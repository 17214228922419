<template>
  <div>
    <div class="toBack">
      <a @click="toBack()">&lt;&nbsp;返回上一级</a>
    </div>

    <Form ref="form" :model="form" :rules="formRules" :label-width="80">
      <FormItem label="主题：" prop="title" style="width: 60%">
        <Input v-model="form.title"></Input>
      </FormItem>

      <FormItem label="图片：" prop="url">
        <!-- 上传按钮 -->
        <Upload v-model="form.url" :show-upload-list="false" :default-file-list="uploadList" :action="uploadUrl" :data="uploadData" :max-size="10240" :on-exceeded-size="()=> this.$Message.warning('文件大小不能超过10M')"
          :format="['jpg','jpeg','png']" :on-format-error="()=> this.$Message.warning('文件格式支持jpg,jpeg,png')" :before-upload="handleBeforeUpload" :on-progress="handleOnProgress" :on-success="handleOnSuccess">
          <Button :disabled="(uploadPercent>0&&uploadPercent<100) || uploadList.length>=9">上传头图</Button><span style="font-size:12px;color:#999999">最多上传9张图片</span>
        </Upload>
        <!-- 上传预览 -->
        <div style="overflow-y: hidden;overflow-x: auto;width: 1000px">
          <div v-for="item,index in uploadList" style="display: inline-block;">
            <img style="width: 100px;height: 100px;margin:10px 0 0 0;cursor: zoom-in;" :src="item.url" @click="MyUtil.showBigImg(item.url)">
            <Icon type="ios-close" style="margin:10px 10px 0 -14px;cursor: pointer;vertical-align: top;background-color:#999999;color:#ffffff" @click="handleOnRemove(index)">
            </Icon>
            <div v-if="item.goodsId" @click="showModal(index)"><a>{{item.goodsId}}<span v-if="item.goodsTitle" :title="item.goodsTitle">:{{item.goodsTitle.substring(0,5)}}</span></a></div>
            <div v-else><a @click="showModal(index)">选择链接商品</a></div>
          </div>
        </div>
        <!-- 进度条 -->
        <Progress v-if="uploadPercent>0&&uploadPercent<100" :percent="uploadPercent" style="width: 100px;" :stroke-width="5"></Progress>
      </FormItem>

      <FormItem label="状态：" prop="status">
        <RadioGroup v-model="form.status" style="margin-top:8px">
          <Radio :label="1">
            启用
          </Radio>
          <Radio :label="0">
            禁用
          </Radio>
        </RadioGroup>
      </FormItem>

      <FormItem label="适用：" prop="type">
        <RadioGroup v-model="form.type" style="margin-top:8px">
          <Radio :label="1">
            平台
          </Radio>
          <Radio :label="2">
            用户
          </Radio>
        </RadioGroup>
      </FormItem>

      <FormItem label="用户：" prop="userId" v-if="form.type==2">
        <Select v-model="form.userId" filterable :remote-method="getUserList" :loading="optionsLoading" clearable style="width: 60%">
          <Option v-for="(item, index) in options" :value="item.id" :key="index">{{item.nickname+'('+item.phone+')'}}</Option>
        </Select>
      </FormItem>

    </Form>

    <div class="toSubmit">
      <Divider />
      <Button type="primary" @click="save()">保存</Button>
      <Button type="default" @click="toBack()">返回</Button>
    </div>

    <!-- 模态框 -->
    <Modal v-model="modal" title="商品列表" :footer-hide="true" width="800">
      <div class="table-input">
        <Input v-model="param.name" clearable placeholder="名称" />
        <Input v-model="param.nickname" clearable placeholder="商家" />
        <Button type="primary" @click="search()">搜索</Button>
      </div>
      <Table :columns="columns" :data="data" @on-selection-change="changeSelection">
        <template slot-scope="{ row, index }" slot="price">
          <span>{{row.minPrice}}</span>~<span>{{row.maxPrice}}</span>
        </template>

        <template slot-scope="{ row, index }" slot="violation">
          <span v-if="row.violation==0" style="color:green">正常</span>
          <span v-if="row.violation==1" style="color:red">违规</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button type="primary" size="small" @click="xuanze(row)">选择</Button>
        </template>
      </Table>
      <Page :total="total" :current="param.page" :page-size="param.rows" show-total show-sizer @on-change="changePage" @on-page-size-change="changePageSize"></Page>

    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      MyUtil: this.MyUtil,
      options: [],
      optionsLoading: false,
      form: {
        title: null,
        url: null,
        status: 1,
        type: 1,
        userId: null,
      },
      formRules: {
        title: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
        url: [
          {
            required: true,
            message: "此项不能为空",
            trigger: "change",
          },
        ],
        userId: [
          {
            required: true,
            message: "此项不能为空",
            type: "number",
            trigger: "change",
          },
        ],
      },
      //上传
      uploadPercent: 0, //上传百分比
      uploadUrl: "", //上传地址
      uploadDir: "headurl", //存储位置
      uploadData: {}, //上传参数
      uploadList: [], //已上传文件列表

      //模态框
      modal: false,

      imgIndex: null,
      //列表
      deleteIds: [],
      param: {
        name: null,
        page: 1,
        rows: 10,
      },
      total: 0,
      data: [],
      columns: [
        // {
        //   type: "selection",
        //   width: 35,
        // },
        {
          title: "ID",
          key: "id",
          width: 80,
        },
        {
          title: "名称",
          key: "name",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "标题",
          key: "title",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "话题",
          key: "topic",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "描述",
          key: "text",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家名称",
          key: "nickname",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家电话",
          key: "phone",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: 80,
        },
      ],
    };
  },
  methods: {
    toBack() {
      this.$router.push({
        name: "headUrlList",
      });
    },

    handleBeforeUpload(file) {
      let fileName = file.name;
      let fileSuffix = fileName.substring(
        fileName.lastIndexOf("."),
        fileName.length
      );
      //获取oss文件上传授权
      return new Promise((resolve, reject) => {
        this.MyUtil.ajax()
          .post("/FileController/getOssAuthorize", {
            dir: this.uploadDir,
          })
          .then((resData) => {
            //设置上传参数
            this.uploadData = resData;
            //设置上传地址
            this.uploadUrl = resData.host;
            //设置存储路径
            this.uploadData.key =
              this.uploadDir + "/" + this.MyUtil.uuid() + fileSuffix;
            resolve(true);
          });
      });
    },
    handleOnProgress(event) {
      //设置上传进度
      if (event.percent) {
        this.uploadPercent = event.percent;
      }
    },
    handleOnSuccess(response, file) {
      //设置已上传文件
      this.uploadList.push({
        name: file.name,
        url: this.MyUtil.fileUrl + this.uploadData.key,
      });
      this.form.url = "XX";
    },

    handleOnRemove(index) {
      this.uploadList.splice(index, 1);
      if (this.uploadList.length > 0) {
        this.form.url = "XX";
      } else {
        this.form.url = null;
      }
    },

    getUserList(query) {
      if (this.MyUtil.isNull(query)) {
        this.options = [];
        return;
      }
      if (this.optionsLoading == false) {
        this.optionsLoading = true;
        setTimeout(() => {
          this.MyUtil.ajax()
            .post("/UserController/getUserList", { name: query })
            .then((resData) => {
              this.options = resData.data;
              this.optionsLoading = false;
              if (this.options) {
                this.options.forEach((element) => {
                  if (element.phone == undefined) {
                    element.phone = "暂无";
                  }
                });
              }
            });
        }, 1000);
      }
    },
    showModal(index) {
      this.imgIndex = index;
      this.modal = true;
    },
    xuanze(row) {
      this.uploadList[this.imgIndex].goodsId = row.id;
      this.uploadList[this.imgIndex].goodsTitle = row.title;
      this.modal = false;
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.urlArray = this.uploadList;
          this.MyUtil.ajax()
            .post("/HeadUrlController/addHeadUrl", this.form)
            .then((resData) => {
              this.$Message.success("保存成功");
              this.toBack();
            });
        }
      });
    },

    getDataList() {
      this.MyUtil.ajax()
        .post("/GoodsController/getGoodsListForWeb", this.param)
        .then((resData) => {
          this.data = resData.data;
          this.total = resData.total;
          if (this.data) {
            this.data.forEach((element) => {
              if (element.headUrl) {
                element.headUrlArr = element.headUrl.split(",");
              }
            });
          }
        });
    },
    changePage(page) {
      this.param.page = page;
      this.getDataList();
    },
    changePageSize(rows) {
      this.param.rows = rows;
      this.getDataList();
    },
    changeSelection(selection) {
      this.deleteIds = [];
      selection.forEach((element) => {
        this.deleteIds.push(element.id);
      });
    },
    search() {
      this.param.page = 1;
      this.getDataList();
    },

    getHeadUrlDetail(row) {
      this.MyUtil.ajax()
        .post("/HeadUrlController/getHeadUrlDetail", { id: row.id })
        .then((resData) => {
          this.form.id = resData.id;
          this.form.title = resData.title;
          this.form.status = resData.status;
          this.form.type = resData.type;
          this.form.userId = resData.userId;
          //加载已上传图片
          if (resData.listHeadUrlDetail) {
            resData.listHeadUrlDetail.forEach((element) => {
              this.uploadList.push({
                name: "",
                url: element.url,
                headUrlId: element.headUrlId,
                goodsId: element.goodsId,
                goodsTitle: element.goodsTitle,
              });
            });
            this.form.url = "XX";
          }
          //加载选择列表
          this.getUserList(row.nickname);
        });
    },
  },
  created() {
    this.getDataList();
    let row = this.$route.params.row;
    if (row) {
      this.getHeadUrlDetail(row);
    }
  },
};
</script>
